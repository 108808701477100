@import "../../core-components/styles/Inputs.scss";
@import "../../core-components/styles/Colours.scss";
@import "../../themes/themes.scss";

#portal-side-nav {
	box-sizing: border-box;
	height: 100%;
	transition: $transition;
	z-index: 10;
	position: relative;
	min-width: 345px;
	max-width: 345px;
	overflow: hidden;
	overflow-y: auto;

	@include themify($themes) {
		background-color: themed('side-nav-colour');
		border-right: 1.8px solid themed('side-nav-border');

		#portal-side-nav-links {
			a, .sub-links-container, .sub-link {
				color: themed('secondary-colour');

				&:hover {
					background-color: rgba(themed('lightest-colour'), 0.6);
				}
			}

			svg {
				color: themed('side-nav-icon-colour');

				&.link-item-alert-icon {
					color: themed('primary-colour');
				}
			}

			.active-link {
				background-color: themed('lightest-colour');
			}
		}
	}

	.side-nav-logo {
		position: absolute;
		top: 50px;
		left: 75px;
	}
	
	#portal-side-nav-content {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		height: 100%;	
		position: relative;
		padding-top: 50px;
		overflow-y: auto;
		overflow-x: hidden;
		// padding-bottom: 130px;

		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */

		&::-webkit-scrollbar { 
			display: none;  /* Safari and Chrome */
		}
	}

	&.switch-showing {
		#portal-side-nav-links {
			padding-bottom: 200px;
		}
	}
	
	#portal-side-nav-links {
		margin-top: 98px;
		padding-bottom: 130px;

		a, .sub-links-container, .sub-link {
			height: 48px;
			margin-bottom: 3px;
			text-decoration: none;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-size: 16px;
			padding: 0px 13.7px;
			border-radius: 5px;
			transition: opacity 0.25s, background-color 0.25s;
			cursor: pointer;
		}

		.sub-links-container {
			margin-bottom: 0;
		}
	
		.active-link {
			opacity: 1;
		}
		
		.sub-link {
			height: auto;
			min-height: 36px;
			margin-top: 5px;
			margin-bottom: 0;
			line-height: 1.3;
			padding: 5px 13px;
			position: relative;

			&:before {
				transition: 200ms ease;
				content: "";
				width: 2px;
				opacity: 0;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				border-radius: 5px 0 0 5px;
				
				@include themify($themes) {
					background-color: rgba(themed('primary-colour'), 0.5);
				}
			}
			
			&.active-link {
				@include themify($themes) {
					background-color: rgba(themed('primary-colour'), 0.1);
				}

				&:before {
					opacity: 1;
					width: 5px;
				}
			}
		}
	}

	button {
		font-size: 12px;
		padding: 14px;
		font-weight: bold;
		height: 48px;

		svg {
			font-size: 16px;
		}
		width: 204px;
	}

	#portal-side-nav-button-spacer {
		flex: 1;
	}
}

.sub-link-outer {
	padding-bottom: 10px;
	margin: 10px 0;

	@include themify($themes) {
		border-bottom: 1px solid themed('side-nav-border');
	}

	.sub-link-expand-icon {
		margin-left: auto;
		transition: 200ms ease;

		&.sub-links-open {
			transform: rotate(-180deg);
		}
	}
}

.side-nav-link-wrapper {
	padding-left: 75px;
	padding-right: 30px;
}

.sub-links-wrapper {
	padding-left: 20px;
}

.side-nav-footer {
	padding-left: 75px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: white;
	padding: 30px 0 30px 75px;
	// box-shadow: 0px -2px 5px rgba(#000, 0.08);
	@include themify($themes) {
		border-top: 1px solid themed('side-nav-border');
	}
}

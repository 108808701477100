.submit-button {
    font-size: 13px;
    width: 122px
}

.comment-date {
    font-size: 12px;
    opacity: 0.6;
    padding-top: 3px;
}

.template {
    width: 100%;
    padding: 2cm;
    background-color: #fff;
    color: #282F4B;
    font-family: 'Lato', helvetica, arial, sans-serif !important;
    font-size: 15px;

    h1 {
        font-size: 38px;
        font-weight: 400;
        font-family: 'Adobe Garamond Pro';
        color: #96BEBA;
        padding: 40px 0px 0px;
        margin: 0;

        span {
            color: #96BEBA;
        }
    }

    h3 {
        font-size: 22px;
        font-weight: 500;
        padding-top: 40px;
        padding-bottom: 20px;
        margin: 0px;
    }

    h4 {
        font-size: 17px;
        font-weight: 600;
        line-height: 24px;
        padding-top: 25px;
        padding-bottom: 15px;
        margin: 0px;
    }

    p {
        padding-bottom: 20px;
        font-size: 15px;
        line-height: 23px;
        margin: 0px !important;
    }

    ol {
        padding-left: 30px;

        li {
            padding-bottom: 15px;
            line-height: 23px;
        }
    }

    ul {
        list-style: none;
        padding-left: 13px;
        margin: 0px !important;

        li {
            padding-bottom: 20px;
            line-height: 23px;
        }
    }

    ul li::before {
        content: '•';
        color: #397D9A; //Unicode character for a bullet point 
        padding-right: 10px;
        font-size: 12px !important;
    }

    hr {
        background-color: #E5E5E5 !important;
        color: #EBEBEB;
        margin: 0px !important;
    }

    .hobson-logo {
        min-width: 132px !important;
        min-height: 39px !important;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    footer {
        font-size: 12px;
        opacity: 0.6;
        line-height: 17px;
        margin: 0px !important;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        font-size: 0.78rem;
        table-layout: fixed;
        padding: 20px 0px;

        tr {
            &:nth-of-type(odd) {
                td {
                    background-color: #F8FCFD !important;
                }
            }
        }

        thead {
            background-color: #ECF6F8;
            font-size: 15px;
            font-weight: 500;
            height: 44px;
            color: #19414B;

            th {
                text-align: center;
                padding: 10px;

                &:first-child {
                    text-align: left;
                }

                &:nth-child(2) {
                    border-right: solid 1px #D1E9EF;
                    border-left: solid 1px #D1E9EF;

                    &.no-side-borders {
                        border-right: none;
                        border-left: none;
                    }
                }
            }
        }

        tbody {
            background-color: #F8FCFD;
            font-size: 14px;

            td {
                border-bottom: solid 1px #D1E9EF;
                text-align: center;
                line-height: 17px;

                &:first-child {
                    text-align: left;
                }

                &:nth-child(2) {
                    border-right: solid 1px #D1E9EF;
                    border-left: solid 1px #D1E9EF;

                    &.no-side-borders {
                        border-right: none;
                        border-left: none;
                    }
                }
            }
        }

        td {
            padding: 10px;
        }

        .asset-allocation-blob-row td {
            cursor: default !important;
        }
    }

    #common-risks-table {
        padding-bottom: 60px;

        tr {
            border-bottom: solid 1px #D1E9EF;

            &:last-child {
                border-bottom: none;
            }
        }

        th,
        td {
            border: none;
            text-align: left !important;
            padding-left: 10px;

            &:first-child {
                border-right: solid 1px #D1E9EF;
                width: 254px;
            }
        }
    }

    .hobson-signatures {
        display: flex;
        flex-direction: row;
        gap: 100px;
        padding-top: 30px;
        padding-bottom: 40px;

        .hobson-names {
            font-weight: 600;
        }
    }

    .reviews-template-input {
        width: 916px;
        height: fit-content;
        color: #333B5A;
        line-height: 24px;

        li {
            padding: 10px 0px;
            line-height: 23px;
        }

        p {
            margin-bottom: 0px !important;
        }
    }

    .reviews-template-input::placeholder {
        color: #333B5A;
        opacity: 0.5;
    }

    .table-cell-with-text-area {
        font-size: 14px;
        line-height: 17px;
        min-height: 50px;
        margin: 0px;
        background-color: #F8FCFD;
        color: #333B5A;
        text-align: left;
        padding: 0px;
        width: 100%;

        ul {
            padding-top: 0px;
        }

        ol {
            padding-top: 0px;
        }

        li {
            padding: 10px 0px;
            line-height: 17px;
        }

        p {
            padding: 10px 10px;
            margin-bottom: 0px !important;
        } 
    }


    .hobson-logo-small {
        width: 14px;
        height: 14px;
    }

    .chart-leyenda {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;

        &.leyendas {
            gap: 20px;
        }
    }

    .reviews-dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;

        &.dot-1 {
            background-color: #4F98FC;
        }

        &.dot-2 {
            background-color: #09B1AA;
        }
    }

    .two-column-list {
        display: grid;
        grid-template-columns: 330px 330px;
    }

    .recommended-tactical-container {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        width: 100%;
        padding-bottom: 10px;
    }

    .recomended-tactical-leyenda {
        display: flex;
        flex-direction: row;
        width: 200px;
    }

    .tactical-leyenda-extremes {
        font-size: 12px;
        width: 100px;
        transform: translateY(-10px);
    }

    .tactical-squares {
        display: flex;
        flex-direction: row;
        gap: 3px;
        margin: 10px;
    }

    .tactical-square {
        width: 32px;
        height: 32px;
        border-radius: 6px;
        background-color: #D3DEE3;

        &.selected-square {
            background-color: #397d9a;
        }
    }

    .table-cell-textarea {
        font-size: 14px;
        line-height: 17px;
        margin: 0px;
        background-color: #F8FCFD;
        color: black;
        text-align: left;
    }

    .cell-paragraph {
        margin-bottom: 0px !important;
        line-height: 17px !important;
    }

    .editable {
        cursor: pointer;
    }

    .mix-table {
        display: flex;
        flex-direction: row;
    }

    .duration-information-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-bottom: 40px;
    }

    .duration-information-bock {
        display: flex;
        flex-direction: column;
        width: fit-content;
        padding-right: 40px;
    }

    .block-info-title {
        font-size: 12px;
        opacity: 0.6;
        padding-bottom: 15px;
    }

    .block-info-value {
        font-size: 18px;
    }

    .current-and-proposed-portfolio-table { 

        .asset-group-title-row {
            font-weight: 600;

            .asset-group-title-cell {
                padding-top: 20px;
            }

        }

        tr {
            &:nth-of-type(odd) {
                td {
                    background-color: #fff !important;
                }
            }
        }

        td {
            background-color: #fff;
            border-right: none !important;
            border-left: none !important;
        };

        th {
            border-right: none !important;
            border-left: none !important;
        }
    }

    .duration-calculation-review-table {
        tr {
            &:last-child {
                background-color: #ECF6F8;

                td {
                    font-weight: 600;
                }
            }
        };

        td,
        th {
            font-size: 10px !important;
            font-weight: 500;
            text-align: right;

            &:nth-child(4),
            &:nth-child(6),
            &:nth-child(8) {
                border-right: solid 1px #D1E9EF;
                border-left: solid 1px #D1E9EF;
            }

            ;

            &:nth-child(9) {
                border-right: solid 1px #D1E9EF;
            }
        }
    }

    .recomendations-input {
        // background-color: #EDEDED;
        padding: 10px 10px 10px 0px;
    }

    .custody-service-fees-table {

        td,
        th {
            &:nth-child(3) {
                border-right: solid 1px #D1E9EF;
            }
        }
    }

    .proposed-portfolio-table-row {
        font-family: "Lato", helvetica, arial, sans-serif !important;
    
        .width-input {
            padding: 0px 10px;
        }
    
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    
        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
    }

    .in-text-drop-down-div {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        padding-bottom: 20px;
    }

    .span-after-drop-down {
        display: flex;
        flex-wrap: wrap;
    }
}
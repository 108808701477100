@import "../../themes/themes.scss";

.banner-info-text {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: 18px;
	font-weight: 600;
	width: 100%;
	gap: 7px;
}

.token-container {
	font-weight: 400;
	font-size: 16px;
	padding: 1px 3px;
	line-height: 1.2;
}

.banner-info {
	@include themify($themes) {
		color: themed('subheader-colour');
	}
}

.banner-highlight-text{
	font-size: 16px;
}

.banner-main-text, .banner-highlight-text, .banner-page {
	display: flex;
	@include themify($themes) {
		color: themed('primary-colour');
	}
}

.banner-intro-text {
	@include themify($themes) {
		color: themed('banner-extension-intro');
	}
}

$OceanBlue: #20596E;
$GlacierTeal: #208BA5;
$LightBlue: #E2F1F4;
$MistGreen: #ced7cd;
$TextOnlyTeal: #7cb3ac;
$PlatinumGrey: #58595b;
$SilverGrey: #b6bec0;
$Success: #4ad3b3;
$Warning: #e8305a;
$Bought: #09B1AA;
$InputColour: #ECEEEF;
$White: #ffffff;
$Black: #000000;
$TextColour: #363636;
$SubHeader: #8E8E8E;
$BorderGrey: #EBEBEB;
$TableDivider: #D1E9EF;
$DragArea: #208BA50D;

:export {
	OceanBlue: $OceanBlue;
	GlacierTeal: $GlacierTeal;
	MistGreen: $MistGreen;
	TextOnlyTeal: $TextOnlyTeal;
	PlatinumGrey: $PlatinumGrey;
	SilverGrey: $SilverGrey;
	Success: $Success;
	Warning: $Warning;
	White: $White;
	Black: $Black;
	DragArea: $DragArea
}

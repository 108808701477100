@import "../../themes/themes.scss";

.notification-bell-wrapper {
	position: relative;

	.notification-bell {
		font-size: 37px;
		cursor: pointer;
		transition: 200ms ease;

		@include themify($themes) {
			fill: themed('secondary-colour');
		}

		&:hover {
			opacity: 0.8;
		}
	}

	.unread-indicator {
		width: 22px;
		height: 22px;
		position: absolute;
		right: -4px;
		top: -1px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		z-index: 1;
		transition: 200ms ease;
		font-size: 12px;
		color: #fff;
		font-weight: 600;
		
		@include themify($themes) {
			background-color: themed('primary-colour');
		}
		border: 1px solid #fff;
	}

	// Experiment. Will keep just in case.
	// &:after {
	// 	content: "";
	// 	width: 40px;
	// 	height: 40px;
	// 	transform: scale(0) translateX(-50%) translateY(-50%);
	// 	top: 50%;
	// 	left: 50%;
	// 	position: absolute;
	// 	border-radius: 50%;
	// 	transition: 200ms ease-in-out;

	// 	@include themify($themes) {
	// 		background-color: rgba(themed('primary-colour'), 0.2);
	// 	}
	// }

	&.notifications-open {
		.notification-bell {
			animation: shake 300ms ease-in-out;
			opacity: 1;
			@include themify($themes) {
				fill: themed('primary-colour');
			}
		}
	}
}

@keyframes shake {
	0% {
		transform: rotate(7deg);
	}

	30% {
		transform: rotate(-7deg);
	}

	60% {
		transform: rotate(4deg);
	}

	90% {
		transform: rotate(-4deg);
	}

	100% {
		transform: rotate(0);
	}
}
@import "../../themes/themes.scss";

.radio-input-field {
	margin-right: 12px;
	cursor: pointer;

	svg {
		width: 18px;
		margin-right: 3px;

		@include themify($themes) {
			fill: themed('primary-colour') !important;
		}
	}

	&:last-of-type {
		margin-right: 0;
	}
}
@import "../../core-components/styles/Inputs.scss";
@import "../../core-components/styles/Colours.scss";
@import "../../themes/themes.scss";

.portal-mobile-banner-container {
	z-index: 11;
	position: relative;

	.banner-account-filters-container {
		transform: none;
		bottom: auto;

		&.filters-slide-in {
			transform: none;
			bottom: auto;
		}
	}
}

#portal-mobile-banner {
	height: 65px;
	box-sizing: border-box;
	transition: all 0.5s;
	padding: 17px 14.5px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	svg {
		font-size: 30px;
		cursor: pointer;
	}

	.unread-indicator {
		background-color: #fff !important;
	}

	@include themify($themes) {
		&.portal-mobile-banner--closed {
			background-color: themed('primary-colour');

			.portal-mobile-banner-logo svg path, svg {
				fill: themed('text-colour-on-primary');
			}
		}
		
		&.portal-mobile-banner--open {
			background-color: themed('background-colour');

			.skye {
				fill: themed('primary-colour');
			}

			.mobile-banner-icons {
				.notification-bell-wrapper {
					.unread-indicator {
						background-color: #fff !important;
						border-color: #fff;
						color: #fff;
					}
				}
			}

			.landscape-banner-valuation {
				h3, h6 {
					color: themed('text-colour') !important;
				}
			}
		}
	}
}

.mobile-name-banner {
	justify-content: space-between;
	width: 100%;
	align-items: center;

	h2 {
		overflow: hidden;
    	text-overflow: ellipsis;
    	white-space: nowrap;
		font-size: 23px !important;
		padding-right: 8px;
	}

	#tax-guide-link-outer {
		text-decoration: none;	
		
		h5 {
			color: #fff;
			font-size: 15px;
		}
		
		.tax-guide-link {
			width: 92px;
			svg {

				color: #fff;
				opacity: 0.8;
				width: 20px;
				height: 20px;
			}
		}
	}
}

.portal-mobile-info-banner {
	padding: 10px 14.5px;
	height: 62px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	text-align: center;
	transition: 300ms ease;
	z-index: 3;
	position: relative;
	
	@include themify($themes) {
		box-shadow: themed('banner-shadow');
	}

	h2, h6 {
		width: 100%;
	}

	&.menu-open {
		h2 {
			color: #fff;
			text-align: left;
			font-size: 33px;
		}

		@include themify($themes) {
			background-color: themed('primary-colour');
		}
	}

	.third-party-menu-closed {
		height: 5px !important
	}

	// For landscape banner change
	@media screen and (min-width: 500px) and (max-height: 400px) {
		display: none;
		
		// &.menu-open {
		// 	display: flex;
		// }
	}
}

.landscape-banner-valuation {
	display: none;
	text-align: center;

	h3, h6 {
		color: #fff !important;
	}

	h3 {
		font-size: 18px;
	}

	h6 {
		font-size: 10px;
		opacity: 0.5;
	}

	// For landscape banner change
	@media screen and (min-width: 500px) and (max-height: 400px){
		display: block;
	}
}

.mobile-banner-icons {
	display: flex;
	align-items: center;

	.notification-bell-wrapper {
		margin-right: 18px;
	
		// !important used to override the id selector on the banner
		.notification-bell {
			opacity: 0.6 !important;
		}

		&.notifications-open {
			.notification-bell {
				opacity: 1 !important;
			}
		}

		.unread-indicator {
			width: 20px;
			height: 20px;
			right: -5px;
			top: -2px;
			background-color: #fff;
			
			@include themify($themes) {
				border-color: themed('primary-colour');
				color: themed('secondary-colour');
			}
		}

		.notification-list-container {
			position: fixed;
			top: 65px;
			left: 0;
			max-height: none;
			height: calc(100vh - 65px);
			width: 100vw;
			transform: translateX(50px);
			border-radius: 0;
			transition: 300ms ease-in-out;

			&.notifications-open {
				transform: translateX(0);
			}

		}
	}
}



@import "../../themes/themes.scss";

.three {
	@include themify($themes) {
		fill: themed('logo-colour');
	}
}

.skye {
	fill: #fff;
}
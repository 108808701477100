@import "../../core-components/styles/Colours.scss";
@import "../../themes/themes.scss";

#portal-banner {
	box-sizing: border-box;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-left: 0px;
	justify-content: space-between;
	align-items: center;
	transition: 500ms ease-in-out;
	position: relative;
	max-width: none;
	z-index: 90;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0px;
		transition: 300ms ease;
	}

	h4 {
		opacity: 0.5;
	}

	h6 {
		color: $SilverGrey;
		text-transform: uppercase;
	}

	.portal-expand {
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.portal-banner-top-container {
		z-index: 100;
	}
	
	#portal-banner-left {
		padding-left: 15px;
	}

	#portal-banner-right {
		display: flex;
		align-items: center;
		// height: 38px;
		z-index: 10;

		.portal-button {
			height: 42px;
		}
	}

	.banner-valuation {
		padding-left: 24px;
		
		h6 {
			letter-spacing: 1px;	
		}

		@include themify($themes) {
			border-left: 2px solid themed('banner-divider');
		}
	}

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-left: 0;
		flex-wrap: wrap;
	}

	&.menu-open {
		@include themify($themes) {
			background-color: themed('primary-colour');

			button {
				background-color: themed('button-colour-secondary');
			}
		}

		h2 {
			color: white;
		}
	}

	&.menu-closed {
		background-color: white;
		h2 {
			@include themify($themes) {
				color: themed('banner-header-colour');
			}
		}

		h4 {
			@include themify($themes) {
				color: themed('subheader-colour');
			}
		}
	}

	&.mobile {
		height: 65px;
		padding: 14px 23px;
		button {
			font-size: 10px;
			svg {
				font-size: 12px;
			}
		}
		transition: background-color 0.5s;
	}

	&.desktop {
		// Not sure if needed but was stopping the border from showing
		// min-height: 135px;
		height: auto;
		padding-top: 38px;

		@include themify($themes) {
			border-bottom: 1px solid themed('banner-border');
		}

		&.skinny-banner {
			height: 72px;

			h2 {
				font-size: 30px;
			}

			h3 {
				font-size: 27px;
			}
		}
	}
}

.banner-info-extension {
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-top: 15px;
	z-index: 90;
	background-color: #fff;

	@include themify($themes) {
		border-top: 1px solid themed('banner-border');
	}

	.container {
		padding-left: 30px;
	}
}

.notifications-wrapper {
	margin-right: 24px;
}

.safe-custody-tooltip{
	
	.portal-tooltip-popup{
		height: auto;
		min-height: fit-content;
		width: 300px;
		color: $TextColour !important;
		white-space: normal;
	}
}

.safe-custody-icon{
	height: 16px !important;
	width: 16px !important;
	margin-right: 3px;	
	transform: translateY(0.5px);
}

.safe-custody-container{
	display: flex;
	flex-direction: row;
	align-items: center;
}

#tax-guide-link-outer {
	text-decoration: none;	
	
	.tax-guide-link {
		cursor: pointer;
		gap: 4px;
		align-items: center;
	
		&:hover {
			opacity: 0.8;
		}
	
		svg {
			width: 20px;
			height: 20px;
	
			@include themify($themes) {
				color: themed('primary-colour');
			}
		}
	}
}

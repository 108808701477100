@import "../../../../core-components/styles/Colours.scss";
@import "../../../../themes/themes.scss";

.transaction-list {
	.td-amount {
		@include themify($themes) {
			color: themed('primary-colour');
		}

		&.positive {
			@include themify($themes) {
				color: themed('bought-colour');
			}
		}

		&.negative {
			@include themify($themes) {
				color: themed('warning-colour');
			}
		}
	}

	.right-align{
		text-align: right;
	}

	.transaction-list__mobile-transaction {
		display: flex;
		align-items: center;
		margin-bottom: 7px;
		padding: 12px 8px;
		border-top: 1px solid;

		&:first-of-type {
			border-top: none;
		}

		@include themify($themes) {
			color: themed('text-colour');
			border-color: themed('hr-colour');
		}

		// @include themify($themes) {
		// 	background-color: themed('lightest-colour');
		// }
	
		.transaction-list__left {
			width: 55%;
		}
	
		.transaction-list__right {
			font-size: 15px;
			font-weight: 700;
			text-align: right;
			width: 45%;

			@include themify($themes) {
				color: themed('primary-colour');
			}
		}
	
		.transaction-list__header {
			font-size: 15px;
		}
	
		.transaction-list__date {
			font-size: 12px;
			opacity: 0.6;
		}
	}
	
	.transaction-list__desktop {
		width: 100%;

		.td-date {
			position: relative;
			
			&:before {
				content: "";
				width: 5px;
				height: 50%;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				border-radius: 10px;

				@include themify($themes) {
					background-color: themed('bought-colour');
				}
			}
		}
		
		tr {	
			th,
			td {
				padding: 13px;
			}
		
			td {
				font-size: 14px;
				background-color: #fff !important;
				@include themify($themes) {
					border-top: themed('transactions-table-row-border');
				}
			}

			&:last-of-type {
				td {
					@include themify($themes) {
						border-bottom: themed('transactions-table-row-border');
					}
				}
			}
		}
	}
}

.portal-card {
	height: 100%;
	
	p {
		max-width: 900px;
	}

	&.no-padding {
		h3 {
			padding: 36px 40px ;
		}
	}
}

.mobile {
	.portal-card {
		&.no-padding {
			h3 {
				padding: 16px 18px ;
			}
		}
	}
}

.card-row {
	display: flex;
	flex-wrap: wrap;
}
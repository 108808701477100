@import "../styles/Colours.scss";
@import "../../themes/themes.scss";

.portal-label {
	width: 100%;
	
	.label-container {
		display: flex;
		justify-content: space-between;
		
		.form-label {
			text-transform: uppercase;
			font-weight: bold;
			line-height: 2em;
			letter-spacing: 1px;

			@include themify($themes) {
				color: themed('input-label-colour');

				&.on-primary {
					color: themed('input-label-colour-primary-highlight');
				}
			};
		}

		.form-error {
			font-weight: normal;
			line-height: 2em;

			@include themify($themes) {
				color: themed('text-colour-warning');
			};
		}
	}
	input {
		font-size: 15px;
		line-height: 15px;
		width: 100%;
		
		&[type="checkbox"] {
			transform: translateY(12px);
			width: auto;
		}

		@include themify($themes) {
			background-color: themed('input-colour');
			border: themed('input-border');

			&:disabled {
				background-color: themed('input-disabled-colour') !important;
				border-color: themed('input-disabled-border-colour') !important;
				color: themed('input-disabled-text-colour') !important;
			}
		};
	}
	&.mobile {
		&.portal-label {
			margin-bottom: 1.3rem;
		}

		.label-container {
			.form-label {
				font-size: 12px;
				margin-bottom: 10px;
			}
		}
		input {
			padding: 15px;
		}
	}
	&.desktop {
		&.portal-label {
			margin-bottom: 20px;
		}

		.label-container {
			.form-label {
				font-size: 14px;
				margin-bottom: 10px;
			}
		}
		input {
			padding: 14px 20px;
		}
	}
}
